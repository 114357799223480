/**
 * @description      :
 * @author           : mario
 * @group            :
 * @created          : 23/04/2024 - 21:09:18
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 23/04/2024
 * - Author          : mario
 * - Modification    :
 **/
import React, { useState } from "react";
import "./Top.css";
// import { FiMenu } from "react-icons/fi";
import MenuTop from "@components/MenuTop";

const Top = ({ sectionId }) => {
	const [hasBackground, setHasBackground] = useState(false);
	const [value, setValue] = useState(0);

	// TODO: Retirar este bloco, após testes
	window.addEventListener("resize", function () {
		setValue(window.innerWidth);
	});

	window.addEventListener("scroll", function () {
		var viewportHeight = window.innerHeight;
		if (window.scrollY > (100 * viewportHeight) / 100)
			setHasBackground(true);
		else setHasBackground(false);
	});

	return (
		<div className="topContainer" id={sectionId}>
			{/* TODO: Retirar este bloco, após testes */}
			<div
				style={{
					background: "black",
					color: "#BBB",
					zIndex: 999999,
					position: "fixed",
					top: 100,
					padding: 10,
					borderRadius: 10,
				}}
			>
				<small
					style={{
						opacity: 0.8,
						display: "block",
						fontSize: 14,
						color: "#EEE",
					}}
				>
					Only For Test...
				</small>
				<span
					style={{
						opacity: 0.8,
						fontWeight: "bold",
						fontSize: "larger",
					}}
				>
					{`Width: ${value}`}px
				</span>
			</div>

			<div className={`menu-container ${ hasBackground ? "menu-top-bg" : "" }`}>
				<div className="logo-wrapper">
					<div className="logo">BITSCALE</div>
				</div>

				<div className="menu-top-wrapper">
					<MenuTop />
				</div>
			</div>

			{/* Menu Aside Container */}
			{/* <div className="menu-aside-container"> */}
			{/* MenuAside */}
			{/* <div className="menu-aside-wrapper"> */}
			{/* Menu Aside Button */}
			{/* <button className="menu-aside-button">
						<FiMenu className="menuIcon" size={46} />
					</button> */}
			{/* </div> */}
			{/* </div> */}

			<div className="top-content-wrapper">
				{/* Top Container */}
				<div className="top-content">
					{/* Centered CTA Title(s) */}
					<div className="top-content-cta-title">
						<h1>Transformando Ideias em Soluções Digitais</h1>
					</div>

					{/* CTA Small Text */}
{/* 					<div className="top-content-cta-small">
						<p>
							Cada projeto é uma jornada única. Estamos aqui para
							oferecer nossa expertise e transformar suas ideias
							em soluções excepcionais.
						</p>
					</div> */}
						
					{/* CTA Button Container */}
					<div className="top-content-cta-button-container">
						<button onClick={() => alert("CTA_TOP_PAGE")}>
							Entre em Contato
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Top;
