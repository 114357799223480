/**
 * @description      :
 * @author           : mario
 * @group            :
 * @created          : 23/04/2024 - 21:09:18
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 23/04/2024
 * - Author          : mario
 * - Modification    :
 **/
import React from "react";
import "./Services.css";
// import { PiArrowRightLight } from "react-icons/pi";

const Services = ({ sectionId }) => {
	return (
		<section className="servicesContainer" id={sectionId}>
			<div className="service-top">
				<span>Nossos</span>
				<h2>Serviços</h2>
			</div>

			<div className="serviceGrid">
				<a href="/" className="serviceCard">
					<div className="serviceCard-top">
						<h3>Imagine</h3>
						<span>1</span>
					</div>
					
					<p>
						Criando um futuro para sua marca por meio de estratégia
						e design centrado no usuário.
					</p>
				</a>

				<a href="/" className="serviceCard">
					<div className="serviceCard-top">
						<h3>Construa</h3>
						<span>2</span>
					</div>

					<p>
						Utilizando os insights do cliente para executar sua
						estratégia com precisão.
					</p>
				</a>

				<a href="/" className="serviceCard">
					<div className="serviceCard-top">
						<h3>Execute</h3>
						<span>3</span>
					</div>

					<p>
						Assegurando desempenho excepcional por meio de operações
						comerciais aprimoradas.
					</p>
				</a>
			</div>
		</section>
	);
};

export default Services;
