/**
    * @description      : 
    * @author           : mario
    * @group            : 
    * @created          : 07/05/2024 - 15:46:16
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 07/05/2024
    * - Author          : mario
    * - Modification    : 
**/
const navigationItems = [
    {
        id: 'home',
        label: 'Home',
    },
    {
        id: 'about',
        label: 'Sobre nós',
    },
    {
        id: 'services',
        label: 'Serviços',
    },
    {
        id: 'contact',
        label: 'Contato',
    },
]

const navigationItemsObj = {
    home: 'home',
    about: 'about',
    services: 'services',
    contact: 'contact',
}

export {
    navigationItems,
    navigationItemsObj,
}