/**
    * @description      : 
    * @author           : mario
    * @group            : 
    * @created          : 24/04/2024 - 12:35:23
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 24/04/2024
    * - Author          : mario
    * - Modification    : 
**/
const socialURLs = {
    tiktok: 'https://www.tiktok.com/@mariooliveiradev',
    instagram: 'https://www.instagram.com/mariooliveira.dev/',
    facebook: 'https://www.facebook.com',
    twitter: 'https://www.x.com',
}

export {
    socialURLs
}