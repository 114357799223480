/**
 * @description      :
 * @author           : mario
 * @group            :
 * @created          : 23/04/2024 - 21:09:18
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 23/04/2024
 * - Author          : mario
 * - Modification    :
 **/
import React from "react";
import "./Footer.css";
import {
	FaFacebookF,
	FaTiktok,
	FaInstagram,
	FaXTwitter,
} from "react-icons/fa6";
import { socialURLs } from "@constants/social";

const Footer = () => {
	return (
		<div className='container'>
			<div className='footerColumns'>
				<SiteMap />
				<Newsletter />
			</div>
 
			<div className='bottom'>
				<div>
					© All Rights Reserved <b>Bitscale</b>
				</div>

				<div className='socialContainer'>
					<div className='social'>
						<a target="_blank" rel="noreferrer" href={socialURLs.facebook}>
							<FaFacebookF />
						</a>
					</div>

					<div className='social'>
						<a target="_blank" rel="noreferrer" href={socialURLs.instagram}>
							<FaInstagram />
						</a>
					</div>

					<div className='social'>
						<a target="_blank" rel="noreferrer" href={socialURLs.tiktok}>
							<FaTiktok />
						</a>
					</div>

					<div className='social'>
						<a target="_blank" rel="noreferrer" href={socialURLs.twitter}>
							<FaXTwitter />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

const SiteMap = () => {
	return (
		<div className='siteMapColumn'>
			<div className='columnTitle'>SiteMap</div>

			<ul className='sitemapItems'>
				<li className='sitemapItem' alt="Home">
					<a rel="noreferrer" name="Home" href="/#home">Home</a>
				</li>
				<li className='sitemapItem' alt="Sobre Nós">
					<a rel="noreferrer" name="Sobre Nós"  href="/#about">Sobre Nós</a>
				</li>
				<li className='sitemapItem' alt="Serviços">
					<a rel="noreferrer" name="Serviços" href="/#services">Serviços</a>
				</li>
				<li className='sitemapItem' alt="Contato">
					<a rel="noreferrer" name="Contato" href="/#contact">Contato</a>
				</li>
			</ul>
		</div>
	);
};

const Newsletter = () => {
	return (
		<div className='newsletterColumn'>
			<div className='columnTitle'>Newsletter</div>

			<div className='emailInputContainer'>
				<input
					className='emailInput'
					type="email"
					placeholder="Entre com seu melhor e-mail"
					onBlur={evt => alert(evt.target.value)}
				/>
				<button className='emailSubmit'>Enviar</button>
			</div>
		</div>
	);
};

export default Footer;
 