/**
    * @description      : 
    * @author           : mario
    * @group            : 
    * @created          : 23/04/2024 - 20:56:04
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 23/04/2024
    * - Author          : mario
    * - Modification    : 
**/
import './App.css';
import Home from '@pages/Home/Home';

function App() {
    return (
        <div className="App">
            <Home />
        </div>
    );
}

export default App;
