/**
 * @description      :
 * @author           : mario
 * @group            :
 * @created          : 07/05/2024 - 13:01:28
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 07/05/2024
 * - Author          : mario
 * - Modification    :
 **/
import React from "react";
import "./MenuTop.css";

import { navigationItems } from "@constants/navigation";

export default function MenuTop() {
	const scrollToSection = async (id) => {
		const element = document.getElementById(id);
		if (element) {
			const elementPosition = element.offsetTop;

			// Go to section
			window.scrollTo({ top: elementPosition, behavior: "smooth" });

			// Wait and mark section in URL
			// setTimeout(() => window.location = `/#${id}`, 100);
		}
	};

	return (
		<div className="menu-top-container">
			<nav>
				<ul>
					{navigationItems.length === 0 ? (
						<li onClick={() => scrollToSection("home")}>
							<span>Home</span>
						</li>
					) : (
						navigationItems.map(({ id, label }) => {
							return (
								<li
									key={id}
									onClick={() => scrollToSection(id)}
								>
									<span>{label}</span>
								</li>
							);
						})
					)}
				</ul>
			</nav>
		</div>
	);
}
