/**
 * @description      :
 * @author           : mario
 * @group            :
 * @created          : 14/05/2024 - 09:06:42
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 14/05/2024
 * - Author          : mario
 * - Modification    :
 **/
import React from "react";
import "./About.css";
import { FaLaptopCode, FaLightbulb } from "react-icons/fa";

export default function About({ sectionId }) {
	return (
		<section className="aboutContainer" id={sectionId}>
			<div className="about-column">
				<FaLaptopCode className="about-icon" />

				<h1 className="about-title">Sobre Nós</h1>

				<p className="about-paragraph">
					Somos uma equipe apaixonada por tecnologia e inovação.
				</p>
			</div>

			<div className="about-column">
				<FaLightbulb className="about-icon" />

				<h1 className="about-title">Sobre Nós</h1>
                
				<p className="about-paragraph">
					Somos uma equipe apaixonada por tecnologia e inovação.
				</p>
			</div>

			<div className="about-column">
				<FaLightbulb className="about-icon" />

				<h1 className="about-title">Sobre Nós</h1>

				<p className="about-paragraph">
					Somos uma equipe apaixonada por tecnologia e inovação.
				</p>
			</div>
		</section>
	);
}
