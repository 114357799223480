/**
 * @description      :
 * @author           : mario
 * @group            :
 * @created          : 23/04/2024 - 19:41:36
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 23/04/2024
 * - Author          : mario
 * - Modification    :
 **/
import React from "react";
import "./Home.css";
import Footer from "@components/Footer";
import Services from "@components/Services";
import Top from "@components/Top";
import { navigationItemsObj } from '@constants/navigation'
import About from "@components/About";
import Contact from "@components/Contact";

const Home = () => {
	return (
		<div className='homeContainer'>
			<Top sectionId={navigationItemsObj.home} />
            
			<div className='gradientContainer'>
				<About sectionId={navigationItemsObj.about} />
				<Services sectionId={navigationItemsObj.services} />
				<Contact sectionId={navigationItemsObj.contact} />
				<Footer />
			</div>
		</div>
	);
};

export default Home;
