/**
    * @description      : 
    * @author           : mario
    * @group            : 
    * @created          : 14/05/2024 - 09:07:35
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 14/05/2024
    * - Author          : mario
    * - Modification    : 
**/
import About from './About';

export default About;