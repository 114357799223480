/**
 * @description      :
 * @author           : mario
 * @group            :
 * @created          : 14/05/2024 - 09:06:42
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 14/05/2024
 * - Author          : mario
 * - Modification    :
 **/
import React, { useState } from "react";
import "./Contact.css";

export default function Contact({ sectionId }) {
	// TODO: Criar Máscara para Email e Telefone/Celular + tratar Data
	const [formData, setFormData] = useState({
		name: "Nome do Cliente",
		title: "Desenvolvedor",
		company: "Triscal Consultoria e Tecnologia",
		email: "mjribeiro@triscal.com.br",
		phone: "(21) 9 6949-6780",
		budget: 60000,
		startDate: new Date().toISOString().split("T")[0],
		projectDetails: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Form Data:", formData);

		// TODO: Criar requisição para o Backend
	};

	return (
		<section className="contactContainer" id={sectionId}>
			{/* <div className="top-content-wrapper"> */}
				<div className="contact-top">
					<span>Entre em</span>
					<h2>Contato</h2>
				</div>

				<div className="contactGrid">
					{/* <div className="slider-wrap">
                        <input
                            className="test"
                            type="range"
                            name="test"
                            onBlur={(evt) => alert(evt.target.value)}
                            min={500}
                            max={30000}
                        />
                    </div> */}

					<form method="POST" onSubmit={handleSubmit}>
						<div className="form-group">
							<label>Nome</label>
							<input
								type="text"
								name="name"
								value={formData.name}
								onChange={handleChange}
							/>
						</div>

						<div className="form-group">
							<label>Título</label>
							<input
								type="text"
								name="title"
								value={formData.title}
								onChange={handleChange}
							/>
						</div>

						<div className="form-group">
							<label>Empresa</label>
							<input
								type="text"
								name="company"
								value={formData.company}
								onChange={handleChange}
							/>
						</div>

						<div className="form-group">
							<label>Email</label>
							<input
								type="email"
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
						</div>

						<div className="form-group">
							<label>Telefone / Celular</label>
							<input
								type="tel"
								name="phone"
								value={formData.phone}
								onChange={handleChange}
							/>
						</div>

						<div className="form-group">
							<label>Budget</label>
							<input
								type="range"
								name="budget"
								min="0"
								max="100000"
								value={formData.budget}
								onChange={handleChange}
							/>
							<span>R${formData.budget.toLocaleString()}</span>
						</div>

						<div className="form-group">
							<label>Quando Quer Iniciar?</label>
							<input
								type="date"
								name="startDate"
								value={formData.startDate}
								onChange={handleChange}
							/>
						</div>

						<div className="form-group">
							<label>Detalhes Do Projeto</label>
							<textarea
								name="projectDetails"
								value={formData.projectDetails}
								onChange={handleChange}
							/>
						</div>

						<div className="form-submit-btn">
							<button type="submit">ENVIAR</button>
						</div>
					</form>
				</div>
			{/* </div> */}
		</section>
	);
}
